import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import Helmet from 'react-helmet';
import qs from 'qs';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Configure, connectSearchBox } from 'react-instantsearch-dom';
import { SEO, SearchResults } from 'components';
import { Autocomplete } from '../components/Search/Topper/Autocomplete';
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import './search.module.scss';

const DEBOUNCE_TIME = 400;
const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY,
);
const indexName = process.env.GATSBY_ALGOLIA_INDEX;

const VirtualSearchBox = connectSearchBox(() => null);

const createURL = state => `?${qs.stringify(state)}`;

const searchStateToUrl = ({ location }, searchState) => {
  if (Object.keys(searchState).length === 0) {
    return '';
  }

  return `${location.pathname}${createURL(searchState)}`;
};

const urlToSearchState = location => qs.parse(location.search.slice(1));

const Search = ({ location }) => {
  const [searchState, setSearchState] = useState(urlToSearchState(location));
  const debouncedSetStateRef = useRef(null);

  React.useEffect(() => {
    clearTimeout(debouncedSetStateRef.current);

    debouncedSetStateRef.current = setTimeout(() => {
      navigate(searchStateToUrl({ location }, searchState));
    }, DEBOUNCE_TIME);
  }, [searchState]);

  const onSubmit = React.useCallback(({ state }) => {
    setSearchState(searchState => ({
      ...searchState,
      query: state.query,
    }));
  }, []);

  const onReset = React.useCallback(() => {
    setSearchState(searchState => ({
      ...searchState,
      query: '',
    }));
  }, []);

  const plugins = React.useMemo(() => {
    const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
      key: 'search',
      limit: 3,
      transformSource({ source }) {
        return {
          ...source,
          onSelect({ item }) {
            setSearchState(searchState => ({
              ...searchState,
              query: item.label,
            }));
          },
        };
      },
    });

    const querySuggestionsPlugin = createQuerySuggestionsPlugin({
      searchClient,
      indexName: process.env.GATSBY_ALGOLIA_QUERY_SUGGESTIONS_INDEX,
      getSearchParams() {
        // This creates a shared `hitsPerPage` value once the duplicates
        // between recent searches and Query Suggestions are removed.
        return recentSearchesPlugin.data.getAlgoliaSearchParams({
          hitsPerPage: 6,
        });
      },
      transformSource({ source }) {
        return {
          ...source,
          onSelect({ item }) {
            setSearchState(searchState => ({
              ...searchState,
              query: item.query,
            }));
          },
        };
      },
    });

    return [querySuggestionsPlugin];
  }, []);

  return (
    <div>
      <Helmet
        bodyAttributes={{
          class: 'has-animation',
        }}>
        <link
          href="https://cdn.jsdelivr.net/npm/@algolia/autocomplete-theme-classic"
          rel="stylesheet"
          type="text/css"
        />
      </Helmet>
      <SEO title="Search" pathname={location.pathname} />
      <InstantSearch
        searchClient={searchClient}
        indexName={indexName}
        searchState={searchState}
        onSearchStateChange={setSearchState}
        createURL={createURL}>
        <Configure hitsPerPage={12} facetingAfterDistinct={true} distinct />
        {/* <SearchTopper /> */}
        <VirtualSearchBox />
        <Autocomplete
          placeholder="Find what you're looking for"
          detachedMediaQuery="none"
          initialState={{
            query: searchState.query,
          }}
          openOnFocus={true}
          onSubmit={onSubmit}
          onReset={onReset}
          plugins={plugins}
        />
        <SearchResults />
      </InstantSearch>
    </div>
  );
};

Search.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default Search;
